import { ref } from '@vue/composition-api'

export default function useSupportReqHandle() {
  const status = ref([
    'PENDING',
    'COMPLETED',
    'REJECTED',
    'SELF_HANDLED',
  ])
  const airlineSupports = ref(['VJ'])

  function resolveBookingRequest(trips) {
    let indexSegment = 1
    return trips.map(trip => ({
      ...trip,
      segments: trip.segments.map(segment => {
        const data = {
          ...segment,
          devSegmentIndex: String(indexSegment),
        }

        indexSegment += 1
        return data
      }),
    }))
  }
  return {
    status, airlineSupports, resolveBookingRequest,
  }
}
